import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./howWineMade.css";
import graps from "../images/graps.webp";
import GrapeSqiser from "../images/Grape-sqiser.webp";
import grape1 from "../images/grape1.webp";
import grape2 from "../images/grape2.webp";
import grape3 from "../images/grape3.webp";
import wineBarrel from "../images/wineBarrel.webp";
import oldimage1 from "../images/BODEGACALDERON037color.webp";
import oldimage2 from "../images/BODEGACALDERON038color.webp";
import oldimage3 from "../images/BODEGACALDERON040color.webp";
import { motion } from "framer-motion";

gsap.registerPlugin(ScrollTrigger);

function HowWineMade() {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const main = useRef();
  const [yOffsetValue, setYOffsetValue] = useState(700);

  useLayoutEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 991;

      if (isMobile) {
        const mobileYMax = 1000; // The maximum y value for mobile view (opposite to the previous value)
        const mobileYMin = 1150; // The minimum y value for mobile view (opposite to the previous value)
        const mobileWidthMax = 350; // Width of window where yOffsetValue is 1000 (opposite to the previous value)
        const mobileWidthMin = 600; // Width of window where yOffsetValue is 1150 (opposite to the previous value)
        const yValue =
          ((mobileYMin - mobileYMax) * (window.innerWidth - mobileWidthMin)) /
            (mobileWidthMax - mobileWidthMin) +
          mobileYMax;

        setYOffsetValue(yValue);
      } else {
        setYOffsetValue(700); // Default y value for desktop view
      }
    };

    handleResize(); // Call handleResize on initial load

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      const grape1 = self.selector(".grape1");
      grape1.forEach((box) => {
        const containerWidth = box.parentNode.offsetWidth;
        const containerheight = box.parentNode.offsetHeight;
        const isMobile = window.innerWidth < 991;
        const xOffsetPercentage = isMobile ? 70 : 150;
        const scalePercentage = window.innerWidth < 500 ? 1.2 : 2;
        const xOffsetPixels = (containerWidth * xOffsetPercentage) / 100;
        const actualy = isMobile
          ? window.innerWidth < 600
            ? yOffsetValue
            : 1000
          : 700;
        gsap.to(box, {
          x: `-=${xOffsetPixels}%`,
          y: actualy,
          rotate: 140,
          scale: scalePercentage,
          scrollTrigger: {
            trigger: box,
            start: "top 20%",
            end: "top -40%",
            scrub: true,
          },
        });
      });
      const grape2 = self.selector(".grape2");
      grape2.forEach((box) => {
        const containerWidth = box.parentNode.offsetWidth;
        const isMobile = window.innerWidth < 991;
        const xOffsetPercentage = isMobile ? 70 : 150;
        const scalePercentage = window.innerWidth < 500 ? 1.2 : 2;
        const xOffsetPixels = (containerWidth * xOffsetPercentage) / 100;
        const actualy = isMobile
          ? window.innerWidth < 600
            ? yOffsetValue + 50
            : 1000
          : 700;
        gsap.to(box, {
          x: `-=${xOffsetPixels}%`,
          y: actualy,
          rotate: 180,
          scale: scalePercentage,
          scrollTrigger: {
            trigger: box,
            start: "top 20%",
            end: "top -40%",
            scrub: true,
          },
        });
      });
      const grape3 = self.selector(".grape3");
      grape3.forEach((box) => {
        const containerWidth = box.parentNode.offsetWidth;
        const isMobile = window.innerWidth < 991;
        const xOffsetPercentage = isMobile ? 70 : 150;
        const scalePercentage = window.innerWidth < 500 ? 1.2 : 2;
        const xOffsetPixels = (containerWidth * xOffsetPercentage) / 100;
        const actualy = isMobile
          ? window.innerWidth < 600
            ? yOffsetValue + 50
            : 1000
          : 700;
        gsap.to(box, {
          x: `-=${xOffsetPixels}%`,
          y: actualy,
          rotate: 200,
          scale: scalePercentage,
          scrollTrigger: {
            trigger: box,
            start: "top 22%",
            end: "top -40%",
            scrub: true,
          },
        });
      });
      const allGrape = self.selector(".all-grape");
      allGrape.forEach((box) => {
        gsap.to(box, {
          opacity: 0,
          scrollTrigger: {
            trigger: box,
            start: "top -40%",
            end: "top -60%",
            scrub: true,
          },
        });
      });
    }, main); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  }, [yOffsetValue]);

  const stickyImageRef = useRef(null);
  const secondDivRef = useRef(null);
  /*   const thirdDivRef = useRef(null);
   */
  useEffect(() => {
    gsap.registerPlugin("ScrollTrigger");

    const stickyImage = stickyImageRef.current;
    const secondDiv = secondDivRef.current;
    /*     const thirdDiv = thirdDivRef.current;
     */
    const stickyImageHeight = stickyImage.clientHeight;
    const secondDivHeight = secondDiv.clientHeight;
    /*     const thirdDivHeight = thirdDiv.clientHeight;
     */
    gsap.to(stickyImage, {
      y: () => {
        const y = stickyImageHeight;
        return `max(0px, ${y}px)`;
      },
      ease: "none",
      scrollTrigger: {
        trigger: stickyImage,
        start: "top top",
        end: () => `+=${secondDivHeight}`,
        pin: true,
        pinSpacing: false,
        scrub: true,
      },
    });
  }, []);
  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };
  return (
    <>
      <motion.div
        className="howMad-Wrapper"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <div className="howMad-grap-perent">
          <div className="howMad-grap-tree-img">
            <motion.img
              src={graps}
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { scale: 1, opacity: 1 },
                hidden: { scale: 1.2, opacity: 0 },
              }}
              alt="grapes farming image"
              width={"100%"}
              height={"100%"}
            />

            <div className="overlay-white-top-"></div>
            <div className="overlay-white-left-"></div>
          </div>
          <div className="howMad-grap-tree-deatiles">
            <motion.h2
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.12 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Ancient elegance: muscat of alexandria wine
            </motion.h2>
            <motion.h4
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.14 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Muscat of alexandria grapes
            </motion.h4>
            <motion.p
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.16 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Step into history with the remarkable Muscat of Alexandria grapes.
              Originating from North Africa, this unique variety has withstood
              the test of time. Its distinct character and fragrance make it
              truly exceptional. Remarkably versatile, these grapes serve a dual
              purpose – destined to become either a refined wine or a delectable
              table grape.
            </motion.p>
            <motion.p
              className="m-0"
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.18 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Muscat of Alexandria grapes adapted perfectly in many areas of the
              mediterranean but found a perfect place in the Valencia region,
              where you can find the biggest plantation area in Spain and one of
              the biggest in Europe.
            </motion.p>
          </div>
        </div>
        <div className="howMad-grap-swizer-perent">
          <div className="howMad-grap-swizer">
            <motion.img
              src={GrapeSqiser}
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.3 }}
              variants={{
                visible: { scale: 1, opacity: 1 },
                hidden: { scale: 1.5, opacity: 0 },
              }}
              alt="grape Squeezer image"
            />
          </div>
          <div className="howMad-grap-swizer-deatiles">
            <motion.h2
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.32 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              MOMPO's sacred wine artistry
            </motion.h2>
            <motion.h4
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.34 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Vinificación process
            </motion.h4>
            <motion.p
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.36 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Indulge in MOMPO's sacred wine, crafted for spiritual sanctity.
              Our winemaking journey begins with the finest grapes, selected
              meticulously. obtaining the must preserving the fruit intensity to
              the maximum we stop the fermentation to preserve the natural
              sweetness of the wine. The result is a wine that transcends, an
              offering of purity. With utmost devotion, MOMPO intertwines
              tradition and faith, producing wines destined for the sanctified
              moments within the church.
            </motion.p>
            <motion.p
              className="m-0"
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.38 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Elevate your spiritual experience with each sip, a testament to
              the divine connection nurtured through every step of creation.
            </motion.p>
          </div>
        </div>
        <div ref={main}>
          <div className="grape1 all-grape">
            <img src={grape1} alt="grape" width={"auto"} height={"500"} />
          </div>
          <div className="grape2 all-grape">
            <img src={grape2} alt="grape" width={"auto"} height={"500"} />
          </div>
          <div className="grape3 all-grape">
            <img src={grape3} alt="grape" width={"auto"} height={"500"} />
          </div>
        </div>
      </motion.div>
      <motion.div
        className="berraleimages"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <div className="berraleimages-container" ref={stickyImageRef}>
          <motion.img
            src={wineBarrel}
            width={"100%"}
            height={"100%"}
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 1, delay: 0.5 }}
            variants={{
              visible: { scale: 1, opacity: 1 },
              hidden: { scale: 1.2, opacity: 0 },
            }}
            alt="wine barrel image"
          />
        </div>
        <div
          className="berraleimages-discription-perent left"
          ref={secondDivRef}
        >
          <div className="berraleimages-discription left">
            {/* Your content for the second div */}
            <motion.h2
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Sacred legacy: Mompó's 170 Years of devotional winemaking
            </motion.h2>
            <motion.p
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Five generations of wine making history for the Mompo family
              dedicated to provide premium wines world wine Mompo mass wine was
              the first product of the company to be distributed outside of
              Europe, most probably the first commercial wine distributed in
              Asia was Mompo, to our knowledge there is no other wine
              distributed in Asia with such a long history.
            </motion.p>
            <motion.p
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Rooted in a history of resilience and faith, Mompó stands as a
              testament to the power of tradition and the sacred bond between
              wine, devotion, and spirituality. With every sip, enthusiasts
              partake in a heritage that spans centuries, uniting the past and
              present in a sacred communion of taste and reverence.
            </motion.p>
          </div>
        </div>
        {/* <div
          className="berraleimages-discription-perent right"
          ref={thirdDivRef}
        >
          <div className="berraleimages-discription right">
            <motion.p
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Throughout the 20th century, Mompó wine retained the
              ecclesiastical authorities' trust, becoming the preferred choice
              for Eucharistic celebrations globally. Its enduring certification
              and unwavering commitment to quality have defined its legacy.
              Mompó's wines encapsulate not only exceptional craftsmanship but
              also the devotion and affection that have been nurtured over
              generations.
            </motion.p>
            <motion.p
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Rooted in a history of resilience and faith, Mompó stands as a
              testament to the power of tradition and the sacred bond between
              wine, devotion, and spirituality. With every sip, enthusiasts
              partake in a heritage that spans centuries, uniting the past and
              present in a sacred communion of taste and reverence.
            </motion.p>
          </div>
        </div> */}
      </motion.div>
      <motion.div
        className="A170YearHistory-container"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <div className="A170YearHistory-headding">
          <motion.h2
            className="m-0"
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 20, opacity: 0 },
            }}
          >
            170 Years of history
          </motion.h2>
          <motion.h4
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 0.5, delay: 0.05 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 20, opacity: 0 },
            }}
          >
            Since 1850
          </motion.h4>
        </div>
        <div className="A170YearHistory">
          <div className="single-iimage-historey">
            <motion.img
              src={oldimage1}
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5, delay: 0.1 }}
              variants={{
                visible: { scale: 1, opacity: 1 },
                hidden: { scale: 1.5, opacity: 0 },
              }}
              alt="grap png"
            />
            <div className="overlay-white-top"></div>
            <div className="overlay-white-bottom"></div>
            <div className="overlay-white-right"></div>
            <div className="overlay-white-left"></div>
          </div>
          <div className="single-iimage-historey">
            <motion.img
              src={oldimage2}
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5, delay: 0.1 }}
              variants={{
                visible: { scale: 1, opacity: 1 },
                hidden: { scale: 1.5, opacity: 0 },
              }}
              alt="grap png"
            />
            <div className="overlay-white-top"></div>
            <div className="overlay-white-bottom"></div>
            <div className="overlay-white-right"></div>
            <div className="overlay-white-left"></div>
          </div>
          <div className="single-iimage-historey">
            <motion.img
              src={oldimage3}
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5, delay: 0.1 }}
              variants={{
                visible: { scale: 1, opacity: 1 },
                hidden: { scale: 1.5, opacity: 0 },
              }}
              alt="grap png"
            />
            <div className="overlay-white-top"></div>
            <div className="overlay-white-bottom"></div>
            <div className="overlay-white-right"></div>
            <div className="overlay-white-left"></div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default HowWineMade;
