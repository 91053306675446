import React, { useState, useEffect } from "react";
import "./OurUniquness.css";
import mompoBottole from "../images/mompo.webp";
import badge from "../images/exclusiveBadge.webp";
import { motion } from "framer-motion";

function OurUniquness() {
  const [winebottle, setWinebottol] = useState(window.innerWidth);
  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  return (
    <motion.div
      className="OurUniquness-wrapper"
      variants={container}
      initial="hidden"
      animate="show"
    >
      <div className="OurUniquness-container">
        <div className="textsecond"></div>
        <div className="shape-conaainer">
          <div className="shape"></div>
          <div className="bottleimage">
            <motion.img
              src={mompoBottole}
              className="bottoleimage"
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 1 }}
              variants={{
                visible: { opacity: 1, rotate: "10deg", x: 0 },
                hidden: {
                  opacity: 0.5,
                  rotate: "0deg",
                  x: winebottle < 500 ? 200 : 500,
                },
              }}
              alt="Wine bottle"
              width={"auto"}
              height={"135%"}
            />
            <motion.img
              src={badge}
              className="badgeimages"
              alt="Wine badge"
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.8 }}
              variants={{
                visible: { opacity: 1, transform: "rotate(10deg)" },
                hidden: { opacity: 0 },
              }}
              height={"150px"}
              width={"auto"}
            />
          </div>
        </div>
      </div>
      <div className="detailes-container">
        <div className="detailes-container-inner">
          <div className="detailes-inner">
            <motion.h2
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: -300, opacity: 0 },
              }}
            >
              Best holy wine MOMPO
            </motion.h2>
            <motion.h4
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.3 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: -300, opacity: 0 },
              }}
            >
              Vino de Misa
            </motion.h4>
            <motion.p
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.32 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 100, opacity: 0 },
              }}
            >
              <span className="compneyname">MOMPO </span>
              wines, a divine experience for church devotees, blend rich flavors
              and elegant notes. Elevate spiritual gatherings with these perfect
              choices. Blessed be the blood of Christ!
            </motion.p>
            <div className="winefeachers">
              <motion.h5
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.8, delay: 0.34 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 100, opacity: 0 },
                }}
              >
                Naturally Sweet
              </motion.h5>
              <motion.p
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.8, delay: 0.36 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 100, opacity: 0 },
                }}
              >
                Naturally sweet wine with <b>no sugar added</b>, sweetness comes
                uniquely from the Moscatel alejandria grapes.
              </motion.p>

              <motion.h5
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.8, delay: 0.38 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 100, opacity: 0 },
                }}
              >
                Aging Into Perfection
              </motion.h5>
              <motion.p
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.8, delay: 0.4 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 100, opacity: 0 },
                }}
              >
                A wine that never dies, MOMPO improves its flavour in the bottle
                and will last decades evolving into a better wine.{" "}
                <b>No Expiration Date!</b>
              </motion.p>
              <motion.h5
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.8, delay: 0.38 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 100, opacity: 0 },
                }}
              >
                Holy Wine
              </motion.h5>
              <motion.p
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.8, delay: 0.4 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 100, opacity: 0 },
                }}
              >
                holy wine <b>seal of approval</b> by the church authorities.
              </motion.p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default OurUniquness;
