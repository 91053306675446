import React, { useEffect } from "react";

import "./about_us.css";

import image1 from "../images/BODEGACALDERON035color.webp";
import image2 from "../images/BODEGACALDERON036color.webp";

import { motion, useAnimation } from "framer-motion";
import Footer from "./footer_ES";

function About_us() {
  const controls = useAnimation();

  useEffect(() => {
    const animation = async () => {
      await controls.start({
        opacity: 1,
        scale: 1,
        filter: "grayscale(0%)",
        transition: {
          ease: "easeInOut",
          duration: 0.5,
          delay: 0.5,
        },
      });
    };

    animation();
  }, [controls]);

  /*   const [offsetY, setOffsetY] = useState(0);
  const [trigger, settrigger] = useState(false);
  const [triggersleepy, settriggersleepy] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 50) {
      settrigger(true);
    }
    if (window.scrollY < 100) {
      settrigger(false);
    }
    if (window.scrollY > 900) {
      settriggersleepy(true);
    }
    if (window.scrollY < 900) {
      settriggersleepy(false);
    }
    setOffsetY(window.pageYOffset);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
   */
  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };
  const heabackgroundzooming = {
    hidden: { scale: 3 },
    show: {
      scale: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
  };
  const opacityrevarce = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
        delay: 0.75,
      },
    },
  };
  const opacity = {
    hidden: { opacity: 1 },
    show: {
      opacity: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <motion.section
        className="mompo-aboutus-wrapper"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <motion.div
          className="background-container"
          variants={heabackgroundzooming}
        >
          <motion.h4 className="es" variants={opacityrevarce}>
            &nbsp; Sobre nosotros &nbsp;
          </motion.h4>
        </motion.div>
      </motion.section>
      <motion.section
        className="about-lagacy"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <div className="lagarcy-image">
          <motion.img
            src={image1}
            style={{ filter: "grayscale(100%)" }} // Set initial filter style to colored
            animate={controls}
            initial={{ filter: "grayscale(100%)", scale: 1.1, opacity: 0 }}
            alt="About us image"
          />
          <div className="overlay-white-top"></div>
          <div className="overlay-white-bottom"></div>
          <div className="overlay-white-right"></div>
          <div className="overlay-white-left"></div>
          <motion.div
            className="overlay-white"
            variants={opacity}
          ></motion.div>{" "}
        </div>
        <div className="lagacy-detailes">
          <div className="lagacy-detailes-inner">
            <motion.h2
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              Una historia de 170 años
            </motion.h2>
            <div className="firdst170yesra-perent">
              <motion.p
                className="m-0 firdst170yesra"
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.02 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                Gracias a más de 170 años de experiencia, los vinos de misa de
                la casa Mompó se han convertido en una referencia en el mundo de
                los vinos sacramentales. La tradición, la calidad y la
                experiencia de Mompó entre los productores españoles han hecho
                nuestros productos líderes en Asia, Europa y América Latina.
              </motion.p>
            </div>
            <motion.h4
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5, delay: 0.04 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              El origen y la leyenda.
            </motion.h4>
            <div className="lagacy-text">
              <motion.p
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.06 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                Cuentan que cuando la filoxera atacó el viñedo europeo, el
                cultivo de la vid desapareció en toda la Europa del norte y en
                el mundo se incrementó demanda de vino. Esta filoxera, que
                terminó llegando al viñedo valenciano, no lo hizo hasta
                principios del siglo XX.
              </motion.p>
              <motion.p
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.08 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                Se decía que la arena evitaba que el insecto de la filoxera
                pudiese penetrar en la vid. Juan Antonio Mompó Plá, por aquel
                entonces tratante de mulas, intercambiaba arena de la playa por
                vino que después vendía en el puerto de Valencia. El alto precio
                que alcanzaba el vino como consecuencia de la plaga europea de
                la filoxera, permitió a Mompó Plá crecer rápidamente en un nuevo
                mercado como era el de la exportación.
              </motion.p>
              <motion.p
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.1 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                Ya desde la segunda mitad del siglo XIX, la evangelización de
                los misioneros españoles fue acompañada por la casa Mompó. Fue
                así como el vino Mompó fue consagrado en los altares misioneros
                en Filipinas, China y Japón, el antiguo Sipango de los
                navegantes españoles. A lo largo del siglo XX, el vino de misa
                Mompó se ha consolidado como el vino escogido para celebrar la
                eucaristía alrededor del mundo.
              </motion.p>
              <motion.p
                className="m-0"
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.12 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                Todo ello, gracias a que no perdió nunca la certificación de
                confianza de las autoridades eclesiásticas, al tiempo que
                mantenía la calidad y el cariño en su manufactura que hasta el
                día de hoy le caracterizan.
              </motion.p>
            </div>
            <motion.h4
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5, delay: 0.14 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              Mompó Plá: el pionero
            </motion.h4>
            <div className="lagacy-text">
              <motion.p
                className="m-0"
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.16 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                La plaga de la filoxera que destruyó buena parte de los viñedos
                europeos en el último tercio del siglo XIX, no entró a Valencia
                sino hasta iniciado el siglo XX. Esto facultaría que la
                industria vitivinícola valenciana se fortaleciera a finales del
                siglo XIX. Fue así como la experiencia acumulada del siglo XIX,
                propiciaría la oportunidad a Juan Antonio Mompó Plá de ocupar un
                importante lugar en el lacerado mercado europeo, y, al mismo
                tiempo, abrir nuevas rutas comerciales a Asia y América. Cuba,
                Argentina, Japón, China y Filipinas serían las nuevas tierras
                donde el vino de misa Mompó se instalaría en los altares para
                convertirse en la sangre de Jesucristo.
              </motion.p>
            </div>
          </div>
          <div className="lagacy-detailes-inner-with-left-image">
            <div className="lagacy-detailes-image">
              <motion.img
                src={image2}
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 1 }}
                variants={{
                  visible: { scale: 1, opacity: 1 },
                  hidden: { scale: 1.5, opacity: 0 },
                }}
                alt="Historical Image in About us"
              />
              <div className="overlay-white-top"></div>
              <div className="overlay-white-bottom"></div>
              <div className="overlay-white-right"></div>
              <div className="overlay-white-left"></div>
            </div>
            <div className="lagacy-detailes-more">
              <motion.h4
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.18 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                Qué es el vino de misa?
              </motion.h4>
              <div className="lagacy-text">
                <motion.p
                  className="m-0"
                  whileInView="visible"
                  viewport={{ once: false }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 20, opacity: 0 },
                  }}
                >
                  Los vinos de misa de calidad están certificados por la
                  autoridad eclesiástica El vino sacramental –vino de misa, vino
                  de comunión o de consagrar- es aquél que se utiliza para la
                  comunión en la misa. Según está prescrito por el Código
                  Canónico debe ser un vino puro, es decir sin ningún tipo de
                  añadido que no provenga de la uva.
                </motion.p>
              </div>
              <motion.h4
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.22 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                MOMPO el vino de los misioneros
              </motion.h4>
              <div className="lagacy-text">
                <motion.p
                  className="m-0"
                  whileInView="visible"
                  viewport={{ once: false }}
                  transition={{ duration: 0.5, delay: 0.24 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 20, opacity: 0 },
                  }}
                >
                  El vino tradicional usado en la misa en España es el vino
                  mistela, que es un vino fortificado con alcohol vínico, lo que
                  permite evitar la oxidación y que se mantenga en perfectas
                  condiciones y que no caduque nunca. De esta forma los
                  misioneros podían viajar a los sitios más recónditos del mundo
                  gozando de un vino en prefectas condiciones.
                </motion.p>
              </div>
            </div>
          </div>
          <div className="lagacy-detailes-inner">
            <motion.h4
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5, delay: 0.26 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              Cómo se hace MOMPO?
            </motion.h4>
            <div className="lagacy-text">
              <motion.p
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.28 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                Mompo se elabora solo con uvas moscatel, esta uva está
                considerada una «vid antigua», y los expertos en vino creen que
                es una de las más antiguas que quedan sin modificar
                genéticamente y que aún persisten. La uva se originó en el Norte
                de África, y el nombre probablemente deriva de su asociación con
                los antiguos egipcios que usaron la uva para hacer vino. Es una
                uva cara de producir por su bajo rendimiento, pero su perfil
                aromático es inconfundible y la hacen una uva ideal tanto para
                vinificar como para ser servida como uva de mesa.
              </motion.p>
              <motion.p
                className="m-0"
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.3 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                Después de obtener el mosto de la uva, la fermentación se corta
                añadiendo alcohol vínico, por lo que el vino es dulce de forma
                natural sin ningún tipo de azúcar añadido exclusivamente con el
                dulzor de la uva.
              </motion.p>
            </div>
            <motion.h4
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5, delay: 0.32 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              Notas de cata
            </motion.h4>
            <div className="lagacy-text">
              <motion.p
                className="m-0"
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.34 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                Aspecto limpio y brillante, color teja. El moscatel de
                Alejandría proporciona una gran intensidad aromática de notas
                florales. Su alto grado alcohólico se equilibra perfectamente
                con el dulzor residual de las uvas cuyo dulzor proviene
                exclusivamente de las uvas y no de azucares añadidos. La
                intensidad y persistencia aromática resulta extremadamente
                agradable sin ser abrumadora al gusto.
              </motion.p>
            </div>
          </div>
        </div>
      </motion.section>

      <Footer />
    </>
  );
}

export default About_us;
