import React, { useEffect, useState } from "react";

import Navbar from "./components/navbar";
import NavbarES from "./components/navbar_ES";

import Herosection from "./components/herosection";
import HerosectionES from "./components/herosection_ES";

import Footer from "./components/footer";
import FooterES from "./components/footer_ES";

import Contact from "./components/contact";
import ContactES from "./components/contact_ES";

import About_us from "./components/about_us";
import About_usEs from "./components/about_us_ES";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./components/ScrollToTop";

import PageNotFound from "./components/PageNotFound";

import OurUniquness from "./components/OurUniquness";
import OurUniqunessES from "./components/OurUniquness_ES";

import HowWineMade from "./components/howWineMade";
import HowWineMadeES from "./components/howWineMade_ES";

import RedirectHandler from "./components/redirectHandler";

import { Helmet } from "react-helmet";
import LoadingSpinner from "./components/LoadingSpinner";

function App() {
  const language = window.location.pathname.split("/")[1];

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    window.addEventListener("load", () => {
      setLoading(false);
    });

    return () => {
      window.removeEventListener("load", () => {});
      clearTimeout(timer);
    };
  }, []);
  return (
    <>
      <Router>
        <ScrollToTop />
        {language === "es" ? <NavbarES /> : <Navbar />}
        <Routes>
          <Route path="/" exect element={<Home />} />
          {/*           English       */}
          <Route path="/en/Contact" element={<Contact />} />
          <Route path="/en/About" element={<About_us />} />
          <Route path="/en/" exect element={<Home />} />
          {/*           español       */}
          <Route path="/es/Contact" element={<ContactES />} />
          <Route path="/es/About" element={<About_usEs />} />
          <Route path="/es/" exect element={<HomeES />} />

          <Route path="/404-page-not-found" exect element={<PageNotFound />} />
          <Route path="*" exect element={<PageNotFound />} />
        </Routes>
        {loading ? <LoadingSpinner /> : ""}
      </Router>
    </>
  );
}

function Home() {
  useEffect(() => {
    const anchor = window.location.hash;
    if (anchor === "#BestWine") {
      scrollToElement("BestWine");
    }
  }, []);
  function scrollToElement(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Holy Wine - Sacred Wine for Church | Mompo mass wine</title>
        <meta
          name="description"
          content="Discover the divine essence of Holy Wine / Sacred Wine, also known as Mass Wine or 'Vino de Misa.' Explore the sacred history and significance of this cherished libation."
        />
        <meta
          name="keywords"
          content="vino de misa, Holy Wine, Mass Wine, wine, vino, Sacred Wine, sacramental wine, ritual wine, church wine, communion wine, liturgical wine, sacrament wine, Eucharistic wine, religious wine, ceremonial wine, sacramental communion wine, catholic wine, Christian sacramental wine, sacramental grape wine, holy communion wine, divine wine, spiritual wine, ceremonial communion wine"
        />
        <link
          data-react-helmet="true"
          href="https://www.mompomasswines.com/en/"
          rel="canonical"
        />
        <meta
          property="og:title"
          content="Holy Wine - Sacred Wine for Church | Mompo Vino de misa"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Discover the divine essence of Holy Wine, also known as Mass Wine or 'Vino de Misa.' Explore the sacred history and significance of this cherished libation."
        />
        <meta property="og:url" content="https://mompomasswines.com/en/" />
        <meta
          property="og:image"
          content="https://mompomasswines.com/static/media/mompo-logo.890a0bdf45ad0cbf345797255f051dd8.svg"
        />
        <meta property="og:site_name" content="Mompo mass Wines" />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            name: "Mompo mass wines",
            telephone: "+34-963-93-57-50",
            email: "tono@arenalwines.com",
            url: "https://mompomasswines.com/en/",
            logo: "https://mompomasswines.com/static/media/mompo-logo.890a0bdf45ad0cbf345797255f051dd8.svg",
            address: {
              "@type": "PostalAddress",
              streetAddress: "Jaime Roig 19 1A",
              addressLocality: "valencia",
              addressRegion: "Spain",
              postalCode: "46010",
              addressCountry: "Spain",
            },
          })}
        </script>
      </Helmet>
      <Herosection />
      <div id="BestWine">
        <OurUniquness />
      </div>
      <HowWineMade />
      <Footer />
    </>
  );
}
function HomeES() {
  useEffect(() => {
    const anchor = window.location.hash;
    if (anchor === "#BestWine") {
      scrollToElement("BestWine");
    }
  }, []);
  function scrollToElement(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>
          Vino Santo - Vino Sagrado para la Iglesia | Mompo Vino de misa
        </title>
        <meta
          name="description"
          content="Descubra la esencia divina del Vino Santo / Vino Sagrado, también conocido como Vino de Misa o 'Mass Wine'. Explore la historia sagrada y el significado de esta preciada libación."
        />
        <meta
          name="keywords"
          content="vino de misa, vino santo, vino sagrado, wine, vino, vino sagrada familia, vino de misa precio, vino para misa, vino catalina, vino de consagración, vino de misa nombre, vino de los curas, vino de misa comprar, vino de misa marcas, vino santo domingo, vino sacramental, vino de la iglesia, vino religioso, Holy Wine, Mass Wine, Sacred Wine, sacramental wine, ritual wine, church wine, communion wine, liturgical wine, sacrament wine, Eucharistic wine, religious wine, ceremonial wine, sacramental communion wine, catholic wine, Christian sacramental wine, sacramental grape wine, holy communion wine, divine wine, spiritual wine, ceremonial communion wine"
        />

        <link
          data-react-helmet="true"
          href="https://www.mompomasswines.com/en/"
          rel="canonical"
        />
        <meta
          property="og:title"
          content="Vino Santo - Vino Sagrado para la Iglesia | Mompo Vino de misa"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Descubra la esencia divina del Vino Santo / Vino Sagrado, también conocido como Vino de Misa o 'Mass Wine'. Explore la historia sagrada y el significado de esta preciada libación."
        />
        <meta property="og:url" content="https://mompomasswines.com/es/" />
        <meta
          property="og:image"
          content="https://mompomasswines.com/static/media/mompo-logo.890a0bdf45ad0cbf345797255f051dd8.svg"
        />
        <meta property="og:site_name" content="Mompo Vino de Misa" />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            name: "Mompo Vino de Misa",
            telephone: "+34-963-93-57-50",
            email: "tono@arenalwines.com",
            url: "https://mompomasswines.com/es/",
            logo: "https://mompomasswines.com/static/media/mompo-logo.890a0bdf45ad0cbf345797255f051dd8.svg",
            address: {
              "@type": "PostalAddress",
              streetAddress: "Jaime Roig 19 1A",
              addressLocality: "valencia",
              addressRegion: "Spain",
              postalCode: "46010",
              addressCountry: "Spain",
            },
          })}
        </script>
      </Helmet>
      <HerosectionES />
      <div id="BestWine">
        <OurUniqunessES />
      </div>
      <HowWineMadeES />
      <FooterES />
    </>
  );
}

export default App;
