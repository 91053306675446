import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import Iframe from "react-iframe";
import Footer from "./footer";
//import Iframe from "react-iframe";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import "./contact.css";
function Contact() {
  const form = useRef();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };
  const heabackgroundzooming = {
    hidden: { scale: 3 },
    show: {
      scale: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
  };
  const opacityrevarce = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
        delay: 0.75,
      },
    },
  };
  const opacity = {
    hidden: { opacity: 1 },
    show: {
      opacity: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
        delay: 1,
      },
    },
  };
  const handleKeyDown = (event) => {
    if (
      event.key === "Backspace" ||
      event.key === "ArrowLeft" ||
      event.key === "Tab" ||
      event.key === "ArrowRight"
    ) {
      return;
    }
    if (!/\d/.test(event.key)) {
      event.preventDefault();
    }
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    msg: "",
  });
  const [mailesended, setmailesended] = useState(false);
  const handleInputChange = (event) => {
    $("#emptyfuild").css({
      display: "none",
    });
    $("#unsuccessmsg").css({
      display: "none",
    });
    $("#suceesmsg").css({
      display: "none",
    });
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const sendEnquiry = (e) => {
    e.preventDefault();
    $("#suceesmsg").css({
      display: "none",
    });
    $("#unsuccessmsg").css({
      display: "none",
    });
    $("#emptyfuild").css({
      display: "none",
    });
    const nameValue = $("#name").val();
    const emailValue = $("#email").val();
    const phoneValue = $("#phone").val();
    const subjectValue = $("#subject").val();
    const msgValue = $("#msg").val();
    if (
      nameValue !== "" &&
      phoneValue !== "" &&
      emailValue !== "" &&
      subjectValue !== "" &&
      msgValue !== ""
    ) {
      $("#emptyfuild").css({
        display: "none",
      });
      $("#suceesmsg").css({
        display: "none",
      });
      $("#unsuccessmsg").css({
        display: "none",
      });
      $("#sendbutton").css({
        display: "none",
      });
      $("#loddingofbutton").css({
        display: "block",
      });
      fetch("https://mompomasswines.com/Api/sendmail/EnquiryMail.php", {
        method: "POST",
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 201) {
            $("#suceesmsg").css({
              display: "block",
            });
            $("#unsuccessmsg").css({
              display: "none",
            });
            $("#emptyfuild").css({
              display: "none",
            });
            $("#sendbutton").css({
              display: "block",
            });
            $("#loddingofbutton").css({
              display: "none",
            });
            console.log("Success:", result);
            setmailesended(true);
            $("#name").val("");
            $("#email").val("");
            $("#phone").val("");
            $("#subject").val("");
            $("#msg").val("");
          }
        })
        .catch((error) => {
          setmailesended(false);
          $("#unsuccessmsg").css({
            display: "block",
          });
          $("#suceesmsg").css({
            display: "none",
          });
          $("#emptyfuild").css({
            display: "none",
          });
          $("#sendbutton").css({
            display: "block",
          });
          $("#loddingofbutton").css({
            display: "none",
          });
          console.error("Error:", error);
        });
    } else {
      $("#emptyfuild").css({
        display: "block",
      });
      $("#unsuccessmsg").css({
        display: "none",
      });
      $("#suceesmsg").css({
        display: "none",
      });
      $("#sendbutton").css({
        display: "block",
      });
      $("#loddingofbutton").css({
        display: "none",
      });
    }
  };
  return (
    <>
      <motion.section
        className="mompo-contect-wrapper"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <motion.div
          className="background-container"
          variants={heabackgroundzooming}
        >
          <motion.h4 variants={opacityrevarce}>
            &nbsp; Contact us &nbsp;
          </motion.h4>
        </motion.div>
      </motion.section>
      <motion.section
        className="mompo-map-headquatar"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <div className="map-container">
          <Iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3079.431584262858!2d-0.36655152359475673!3d39.48216821198842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd6048a642faa70f%3A0xbb1b2a04eefae4bc!2sC.%20de%20Jaume%20Roig%2C%2019%2C%2046010%20Val%C3%A8ncia%2C%20Spain!5e0!3m2!1sen!2sin!4v1690362897853!5m2!1sen!2sin"
            id="myId"
            className="mapwithback"
            display="initial"
            position="absolute"
            loading="lazy"
          ></Iframe>
          <div className="overlay-white-top"></div>
          <div className="overlay-white-bottom"></div>
          <div className="overlay-white-right"></div>
          <div className="overlay-white-left"></div>
          <motion.div
            className="overlay-white"
            variants={opacity}
          ></motion.div>{" "}
        </div>
      </motion.section>
      <motion.section
        className="mompo-send-wrapper"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <div className="mompo-sendcontact-box">
          <div className="mompo-sandcontactgrid-box">
            <div className="mompo-main-sendcontact-sec">
              <div className="mompo-main-sendcontact-heding">
                <motion.h1
                  whileInView="visible"
                  viewport={{ once: false }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 20, opacity: 0 },
                  }}
                >
                  We’d love to hear from you!
                </motion.h1>
              </div>
              <div className="mompo-sendsetailes">
                <div className="mompo-main-sendcontact-send">
                  <form /* ref={form} onSubmit={sendEmail} */>
                    <div className="mompo-end-inner">
                      <motion.div
                        className="mompo-send-innner-inner"
                        whileInView="visible"
                        viewport={{ once: false }}
                        transition={{ duration: 0.5, delay: 0.04 }}
                        variants={{
                          visible: { y: 0, opacity: 1 },
                          hidden: { y: 20, opacity: 0 },
                        }}
                      >
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="inputtag-sendcontect"
                          onChange={handleInputChange}
                        />
                      </motion.div>
                      <motion.div
                        className="mompo-send-innner-inner"
                        whileInView="visible"
                        viewport={{ once: false }}
                        transition={{ duration: 0.5, delay: 0.06 }}
                        variants={{
                          visible: { y: 0, opacity: 1 },
                          hidden: { y: 20, opacity: 0 },
                        }}
                      >
                        <label htmlFor="email">E-mail</label>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          className="inputtag-sendcontect"
                          onChange={handleInputChange}
                        />
                      </motion.div>
                    </div>
                    <div className="mompo-end-inner">
                      <motion.div
                        className="mompo-send-innner-inner"
                        whileInView="visible"
                        viewport={{ once: false }}
                        transition={{ duration: 0.5, delay: 0.08 }}
                        variants={{
                          visible: { y: 0, opacity: 1 },
                          hidden: { y: 20, opacity: 0 },
                        }}
                      >
                        <label htmlFor="phone">Mobile number</label>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          className="inputtag-sendcontect"
                          onKeyDown={handleKeyDown}
                          onChange={handleInputChange}
                        />
                      </motion.div>
                      <motion.div
                        className="mompo-send-innner-inner"
                        whileInView="visible"
                        viewport={{ once: false }}
                        transition={{ duration: 0.5, delay: 0.1 }}
                        variants={{
                          visible: { y: 0, opacity: 1 },
                          hidden: { y: 20, opacity: 0 },
                        }}
                      >
                        <label htmlFor="subject">Subject</label>
                        <input
                          type="text"
                          name="subject"
                          id="subject"
                          className="inputtag-sendcontect"
                          onChange={handleInputChange}
                        />
                      </motion.div>
                    </div>
                    <div className="mompo-end-inner">
                      <motion.div
                        className="mompo-send-innner-inner"
                        style={{ width: "100%" }}
                        whileInView="visible"
                        viewport={{ once: false }}
                        transition={{ duration: 0.5, delay: 0.12 }}
                        variants={{
                          visible: { y: 0, opacity: 1 },
                          hidden: { y: 20, opacity: 0 },
                        }}
                      >
                        <label htmlFor="msg">Message</label>
                        <textarea
                          name="msg"
                          rows="5"
                          id="msg"
                          className="inputtag-sendcontect"
                          onChange={handleInputChange}
                        ></textarea>
                      </motion.div>
                    </div>
                    <div style={{ float: "left ", width: "80%" }}>
                      <p className="suceesmsg" id="suceesmsg">
                        Your Message is sent Successfully
                      </p>
                      <p className="unsuccessmsg" id="unsuccessmsg">
                        Something went wrong, your message isn't sent. Try
                        again!!!
                      </p>
                      <p className="unsuccessmsg" id="emptyfuild">
                        You have empty fields, fill them first.
                      </p>
                    </div>
                    <div style={{ float: "right ", width: "20%" }}>
                      <motion.button
                        type="button"
                        className="mompo-sendcontact-btn"
                        style={{ float: "right " }}
                        whileInView="visible"
                        viewport={{ once: false }}
                        transition={{ duration: 0.5, delay: 0.14 }}
                        variants={{
                          visible: { y: 0, opacity: 1 },
                          hidden: { y: 20, opacity: 0 },
                        }}
                        onClick={sendEnquiry}
                      >
                        <span className="sendbutton" id="sendbutton">
                          Send
                        </span>
                        <i
                          className="fa fa-spinner loddingofbutton"
                          id="loddingofbutton"
                        ></i>
                      </motion.button>
                    </div>
                  </form>
                </div>
                <div className="contactdetailes">
                  <motion.div
                    className="contactdetailes-address contactdetailes-width  d-flex align-items-center justify-content-center"
                    whileInView="visible"
                    viewport={{ once: false }}
                    transition={{ duration: 0.5, delay: 0.16 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 20, opacity: 0 },
                    }}
                  >
                    <div>
                      <h5>Physical Address</h5>
                      <p>Jaime Roig 19 1A 46010 valencia Spain</p>
                    </div>
                  </motion.div>
                  <motion.div
                    className="contactdetailes-email contactdetailes-width  d-flex align-items-center justify-content-center"
                    whileInView="visible"
                    viewport={{ once: false }}
                    transition={{ duration: 0.5, delay: 0.18 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 20, opacity: 0 },
                    }}
                  >
                    <div>
                      <h5>Email Address</h5>
                      <p>tono@arenalwines.com </p>
                      {/* <p
                        style={{
                          lineHeight: "1.2",
                          fontSize: "16px",
                          color: "#b6b6b6",
                        }}
                      >
                        Our distributors contacts in China, Vietnam,
                        Philippines, Panama, Spain, Nicaragua
                      </p> */}
                    </div>
                  </motion.div>
                  <motion.div
                    className="contactdetailes-phone contactdetailes-width d-flex align-items-center justify-content-center"
                    whileInView="visible"
                    viewport={{ once: false }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 20, opacity: 0 },
                    }}
                  >
                    <div>
                      <h5>Phone Numbers</h5>
                      <p>+34-963-93-57-50</p>
                    </div>
                  </motion.div>
                  <motion.div
                    className="contactdetailes-phone contactdetailes-width d-flex align-items-center justify-content-center"
                    whileInView="visible"
                    viewport={{ once: false }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 20, opacity: 0 },
                    }}
                  >
                    <div>
                      <h5>Distributors</h5>
                      <p>
                        China, Vietnam, Philippines, Panama, Spain, Nicaragua
                      </p>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.section>
      <Footer />
    </>
  );
}

export default Contact;
