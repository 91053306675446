import React from "react";
import "./footer.css";
import logo from "../images/mompo-logo.svg";
function Footer() {
  return (
    <>
      <div className="mompo-footer-wrapper">
        <div className="footer-container">
          <div className="footer-copyright">
            <p>
              Copyright © {new Date().getFullYear()},<br />
              All rights reserved By MOMPO
            </p>
          </div>
          <div className="mompo-logo">
            <img src={logo} alt="Logo" height={"112"} width={"auto"} />
          </div>
          <div className="socail-media">
            <a
              href="https://www.facebook.com/tonomompo/"
              target="_blank"
              style={{ textDecoration: "none" }}
              aria-label="facebook"
              rel="noopener noreferrer"
            >
              <div className="social-media-inner">
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </div>
            </a>
            <a
              href="https://instagram.com/mompovinodemisa?igshid=MzRlODBiNWFlZA=="
              target="_blank"
              style={{ textDecoration: "none" }}
              aria-label="instagram"
              rel="noopener noreferrer"
            >
              <div className="social-media-inner">
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </div>
            </a>
            <a
              href="https://www.linkedin.com/company/mompo-mass-wines/"
              target="_blank"
              style={{ textDecoration: "none" }}
              aria-label="linkdin"
              rel="noopener noreferrer"
            >
              <div className="social-media-inner" style={{ margin: "0%" }}>
                <i className="fa fa-linkedin" aria-hidden="true"></i>
              </div>
            </a>
            {/* <div className="social-media-inner">
              <i className="fa fa-twitter" aria-hidden="true"></i>
            </div>
            <div className="social-media-inner">
              <i className="fa fa-youtube-play" aria-hidden="true"></i>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
