import React from "react";
import "./LoadingSpinner.css"; // Create a CSS file for styling the loading spinner
import Loadding from "../images/WineLoadding.gif";
const LoadingSpinner = () => {
  return (
    <div className="loader-wrapper">
      <div className="loader">
        <img src={Loadding} alt="Loading..." className="loading-image" />
      </div>
    </div>
  );
};

export default LoadingSpinner;
