import React, { useEffect } from "react";

import "./about_us.css";

import image1 from "../images/BODEGACALDERON035color.webp";
import image2 from "../images/BODEGACALDERON036color.webp";

import { motion, useAnimation } from "framer-motion";
import Footer from "./footer";

function About_us() {
  const controls = useAnimation();

  useEffect(() => {
    const animation = async () => {
      await controls.start({
        opacity: 1,
        scale: 1,
        filter: "grayscale(0%)",
        transition: {
          ease: "easeInOut",
          duration: 0.5,
          delay: 0.5,
        },
      });
    };

    animation();
  }, [controls]);

  /*   const [offsetY, setOffsetY] = useState(0);
  const [trigger, settrigger] = useState(false);
  const [triggersleepy, settriggersleepy] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 50) {
      settrigger(true);
    }
    if (window.scrollY < 100) {
      settrigger(false);
    }
    if (window.scrollY > 900) {
      settriggersleepy(true);
    }
    if (window.scrollY < 900) {
      settriggersleepy(false);
    }
    setOffsetY(window.pageYOffset);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
   */
  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };
  const heabackgroundzooming = {
    hidden: { scale: 3 },
    show: {
      scale: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
  };
  const opacityrevarce = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
        delay: 0.75,
      },
    },
  };
  const opacity = {
    hidden: { opacity: 1 },
    show: {
      opacity: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <motion.section
        className="mompo-aboutus-wrapper"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <motion.div
          className="background-container"
          variants={heabackgroundzooming}
        >
          <motion.h4 variants={opacityrevarce}>
            &nbsp; about us &nbsp;
          </motion.h4>
        </motion.div>
      </motion.section>
      <motion.section
        className="about-lagacy"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <div className="lagarcy-image">
          <motion.img
            src={image1}
            style={{ filter: "grayscale(100%)" }} // Set initial filter style to colored
            animate={controls}
            initial={{ filter: "grayscale(100%)", scale: 1.1, opacity: 0 }}
            alt="About us image"
          />
          <div className="overlay-white-top"></div>
          <div className="overlay-white-bottom"></div>
          <div className="overlay-white-right"></div>
          <div className="overlay-white-left"></div>
          <motion.div
            className="overlay-white"
            variants={opacity}
          ></motion.div>{" "}
        </div>
        <div className="lagacy-detailes">
          <div className="lagacy-detailes-inner">
            <motion.h2
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              A history of 170 years.
            </motion.h2>
            <div className="firdst170yesra-perent">
              <motion.p
                className="m-0 firdst170yesra"
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.02 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                Thanks to more than 170 years of experience, the Mompó house
                mass wines have become a reference in the world of sacramental
                wines. The tradition, quality and experience of Mompó among
                Spanish producers have made our products leaders in Asia, Europe
                and Latin America.
              </motion.p>
            </div>
            <motion.h4
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5, delay: 0.04 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              The origin and the legend.
            </motion.h4>
            <div className="lagacy-text">
              <motion.p
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.06 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                They say that when phylloxera attacked the European vineyard,
                the cultivation of the vine disappeared in all of northern
                Europe and the demand for wine increased in the world. This
                phylloxera, which ended up reaching the Valencian vineyard, did
                not do so until the beginning of the 20th century.
              </motion.p>
              <motion.p
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.08 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                Juan Antonio Mompó Plá, then a mule dealer, exchanged sand from
                the beach for wine that he later sold in the port of Valencia.
                The sand prevented the phylloxera insect from penetrating the
                vine. The high price that wine reached as a result of the
                European phylloxera plague allowed Mompó Plá to grow rapidly in
                a new market such as exports.
              </motion.p>
              <motion.p
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.1 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                Spanish missionaries was accompanied by the Mompó house. This is
                how Mompó wine was consecrated on missionary altars in the
                Philippines, China and Japan, the ancient Sipango of Spanish
                navigators. Throughout the 20th century, Mompó mass wine has
                established itself as the wine of choice to celebrate the
                Eucharist around the world.
              </motion.p>
              <motion.p
                className="m-0"
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.12 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                All this, thanks to the fact that it never lost the trustworthy
                certification of the ecclesiastical authorities, while
                maintaining the quality and affection in its manufacture that
                characterize it to this day.
              </motion.p>
            </div>
            <motion.h4
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5, delay: 0.14 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              Mompó Plá: the pioneer
            </motion.h4>
            <div className="lagacy-text">
              <motion.p
                className="m-0"
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.16 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                The phylloxera plague that destroyed a good part of the European
                vineyards in the last third of the 19th century, did not enter
                Valencia until the beginning of the 20th century. This would
                enable the Valencian wine industry to strengthen at the end of
                the 19th century. It was in this way that the accumulated
                experience of the 19th century would give Juan Antonio Mompó Plá
                the opportunity to occupy an important place in the lacerated
                European market, and, at the same time, open new trade routes to
                Asia and America. Cuba, Argentina, Japan, China and the
                Philippines would be the new lands where the Mompó mass wine
                would settle on the altars to become the blood of Jesus Christ.
              </motion.p>
            </div>
          </div>
          <div className="lagacy-detailes-inner-with-left-image">
            <div className="lagacy-detailes-image">
              <motion.img
                src={image2}
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 1 }}
                variants={{
                  visible: { scale: 1, opacity: 1 },
                  hidden: { scale: 1.5, opacity: 0 },
                }}
                alt="Historical Image in About us"
              />
              <div className="overlay-white-top"></div>
              <div className="overlay-white-bottom"></div>
              <div className="overlay-white-right"></div>
              <div className="overlay-white-left"></div>
            </div>
            <div className="lagacy-detailes-more">
              <motion.h4
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.18 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                What is mass wine?
              </motion.h4>
              <div className="lagacy-text">
                <motion.p
                  className="m-0"
                  whileInView="visible"
                  viewport={{ once: false }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 20, opacity: 0 },
                  }}
                >
                  Quality mass wines are certified by the ecclesiastical
                  authority. Sacramental wine – mass wine, communion or
                  consecration wine – is that used for communion at mass. As
                  prescribed by the Canonical Code, it must be a pure wine, that
                  is, without any type of addition that does not come from the
                  grape.
                </motion.p>
              </div>
              <motion.h4
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.22 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                Mompo the wine of the missionaries
              </motion.h4>
              <div className="lagacy-text">
                <motion.p
                  className="m-0"
                  whileInView="visible"
                  viewport={{ once: false }}
                  transition={{ duration: 0.5, delay: 0.24 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 20, opacity: 0 },
                  }}
                >
                  The traditional wine used in the mass in Spain is mistela
                  wine, which is a wine fortified with wine alcohol, which
                  prevents oxidation and keeps it in perfect condition and never
                  expires. In this way the missionaries could travel to the most
                  remote places in the world enjoying a wine in perfect
                  conditions.
                </motion.p>
              </div>
            </div>
          </div>
          <div className="lagacy-detailes-inner">
            <motion.h4
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5, delay: 0.26 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              How is MOMPO made?
            </motion.h4>
            <div className="lagacy-text">
              <motion.p
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.28 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                Mompo is made only from muscatel grapes, this grape is
                considered an “ancient vine” and is believed by wine experts to
                be one of the oldest surviving grapes. The grape originated in
                North Africa, and the name likely derives from its association
                with the ancient Egyptians who used the grape to make wine. It
                is an expensive grape to produce due to its low yield, but its
                aromatic profile is unmistakable and makes it an ideal grape
                both for winemaking and to be served as a table grape.
              </motion.p>
              <motion.p
                className="m-0"
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.3 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                After obtaining the must from the grapes, the fermentation is
                stopped by adding wine alcohol, so the wine is naturally sweet
                without any type of sugar added exclusively with the sweetness
                of the grapes.
              </motion.p>
            </div>
            <motion.h4
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5, delay: 0.32 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 20, opacity: 0 },
              }}
            >
              Tasting notes
            </motion.h4>
            <div className="lagacy-text">
              <motion.p
                className="m-0"
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.5, delay: 0.34 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 20, opacity: 0 },
                }}
              >
                Clean and bright appearance, tile colour. The Alexandria
                muscatel provides a great aromatic intensity of floral notes.
                Its high alcohol content is perfectly balanced by the residual
                sweetness of the grapes, whose sweetness comes exclusively from
                the grapes and not from added sugars. The aromatic intensity and
                persistence is extremely pleasant without being overwhelming to
                the taste.
              </motion.p>
            </div>
          </div>
        </div>
      </motion.section>

      <Footer />
    </>
  );
}

export default About_us;
