import React from "react";
import { HashLink } from "react-router-hash-link";
import pagenotfound from "../images/pagenotfound.svg";
import "./PageNotFound.css";

import { Helmet } from "react-helmet";
function PageNotFound() {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>404 Page Not Found </title>
        <link
          data-react-helmet="true"
          href="https://www.mompomasswines.com/en/"
          rel="canonical"
        />
      </Helmet>
      <div className="mompo-404-wrapper">
        <div className="mompo-dilaies-404">
          <div className="mompo-image-box">
            <img src={pagenotfound} alt="Page not Found.." />
          </div>
          <div className="mompo-text-box">
            <h1>oooooooops! page not found</h1>
            <p>Sorry, the page you're looking for doesn't exist.</p>
            <HashLink smooth to="/" className="auout_usbtn">
              Go back
            </HashLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
